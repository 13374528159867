import React from 'react'

const Feature1 = () => {
  return (
    <div className="effortless position-relative" id="features" style={{marginTop: '-150px', zIndex: 200}}> 
  <div className="container">
    <div className="row align-items-center justify-content-between">
      <div className="col-md-6">
        <div className="section-head wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
          <h4 className="lasthead">Features Of Arbitrage Global</h4>
          <h2 className="title">Effortless Crypto
            Transactions</h2>
          <p className="text">
            The simple way to grow your money like the world’s most
            sophisticated investors.
          </p>
        </div>
        <ul className="list">
          <li className="list-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <div className="thumb"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659904273/transfer-1.png" alt="user" /></div>
            <p className="text">Trusted</p>
          </li>
          <li className="list-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <div className="thumb"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659904273/transfer-2.png" alt="user" /></div>
            <p className="text">Competitive</p>
          </li>
          <li className="list-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <div className="thumb"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659904273/transfer-3.png" alt="user" /></div>
            <p className="text">Secure</p>
          </li>
        </ul>
      </div>
      <div className="col-md-6  d-md-block">
        <div className="pic">
          <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1696103110/arbitrageglobalcom.png" alt="user" className="transfer" />
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Feature1