import React, {Fragment, useEffect, useState} from 'react';
import apiClient from "../../http-common";
import { useQuery } from "react-query";
import ReactApexChart from 'react-apexcharts';

const Plans = () => {
  const [plans, setPlans] = useState('');
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  const { isLoading: isLoadingPlans, refetch: loadPlans } = useQuery(
    "investment-plans",
    async () => {
      return await apiClient.get("/api/investment-plans");
    },
    {
      enabled: true,
      onSuccess: (res) => {
        setPlans(res.data.plans);
      },
      onError: (err) => {
        // console.log(err.response?.data || err);
      },
    }
  );

  useEffect(()=>{
    loadPlans();
  }, [])

    return (
    <div className="container-fluid" style={{position: 'relative', zIndex: 30, backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1693574953/lincfooter.png)"}}>
          {isLoadingPlans ? <span>loading plans...</span> :
      plans.length > 0 && 
  <div className="container">    
    <div className="col-12">
      <h2 className="title text-center text-white pt-4">OUR INVESTMENT PLANS </h2>
      <p className="text-center text-white p-4">Buy our shares and earn daily dividends from the profits generated daily</p>
    </div>
    <div className='row'>

{plans.map(plan => (
  <div key={plan.id} className="col-md-3 mb-7">
<div className="text-center m-2 p-3 rounded rounded-lg" style={{boxShadow: 'rgba(255, 255, 255, 0.99) 0px  5px 10px 0px', borderradius: 10, backgroundcolor: '#00923f'}}>
  <div className="icon text-center">
    
  <ReactApexChart options={{
              chart: {
                type: 'pie',
              },
              colors: ['#007b3e', '#ff6600'],
              legend: {
                show: false,
                showForSingleSeries: false,
                showForNullSeries: false,
                showForZeroSeries: false,
                position: 'middle'
              },
              
              labels: ['ROI', ''],
              dataLabels: {
                offsetX: 0,
                offsetY: 0,
                textAnchor: 'start',
                formatter(val, opts) {
                  const name = opts.w.globals.labels[opts.seriesIndex]
                  if (val == parseFloat(plan.total_earning)) {
                    return [name, val.toFixed(1) + '%']
                  }else{
                    return ['']
                  }
                  
                },
                
              },
              plotOptions: {
                pie: {
                  dataLabels: {
                    offset: -20
                  }
                }
              }
              ,
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  
                }
              }]
            }}
            series={[parseFloat(plan.total_earning), parseFloat(100 - plan.total_earning)]}
            type="pie" height={180}
            />
  
  </div>
  <div className="text-center w-100 text-white mt-2 p-1 rounded" style={{background: 'linear-gradient(90deg, #00923f 0%, #02622e 35%, #027234 100%)'}}><h4 style={{fontSize: '16px'}} className="percentage text-center mt-1 p-0 text-white mb-1">{plan.name}</h4></div>
  <div className="media d-block font-size-1 text-body mb-2">
    <div className="media-body text-white">
      <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> Min Amt: {formatPrice(plan.min_amount )}
    </div>
  </div>
  <div className="media d-block font-size-1 text-body mb-2">
    <div className="media-body text-white">
      <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> Max Amt: {formatPrice(plan.amount_invested )}
    </div>
  </div>
  <div className="media d-block font-size-1 text-body mb-2">
    <div className="media-body text-white">
      <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" />ROI: {`${plan.total_earning}%`} 
    </div>
  </div>

  <div className="media d-block font-size-4 text-body mb-2">
    <div className="media-body text-white">
      <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" />  {plan.duration}
    </div>
  </div>

  <div className="media d-block font-size-4 text-body mb-2">
    <div className="media-body text-white">
      <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" />  {parseFloat(plan.bonus_per_downline)+ '% BONUS'}
    </div>
  </div>
  <div className="text-center">
    <a className="btn text-white" href="https://dashboard.arbitrageglobal.net/" style={{boxShadow: '0px 5px 15px 0px rgb(230 97 43 / 40%)', background: 'linear-gradient(90deg, #00923f 0%, #02622e 35%, #027234 100%)'}}>invest now
    </a>
  </div>
</div>
</div>
))}
  </div>
</div>
}
</div>
)}
export default Plans;

