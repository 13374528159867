import React from 'react'

const Feature2 = () => {
  return (
    <section className="why-affiliate-section" style={{marginTop: "-190px"}}>
  <div className="container">
    <div className="row justify-content-between align-items-center">
      <div className="col-lg-6">
        <div className="why-affiliate-content">
          <div className="upper-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <h4 className="lasthead">Why Should You Join Us</h4>
            <h2 className="title">On-the-Go and Seamless trading integration</h2>
            <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
             <strong> With the Arbitrage Global App, your money works for you!</strong>
            </p>
            <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
            Our goal is to help you grow and sustain an alternative pool of income by making your digital assets work for you! We offer short-term and long-term Investment options so you own your own profitable ecosystem and with your digital wallet you have access to funding right in your pocket. All the personal information shared by clients on this platform is protected and our live chats are end-to-end encrypted
          </p>
          <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
          One of our strongest advantages over our competitors is that we provide the utmost flexibility and the most important insurance on funds invested with us. We ensure far more confidence for our investors, secure their deposits to the utmost, and let us put all the efforts upon achieving the maximum business profitability.
          </p>
          </div>
          <div className="why-area">
            <div className="why-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
              <div className="why-inner">
                <div className="why-thumb">
                  <img src="assets/img/why1.png" alt="why" />
                </div>
                <div className="why-content">
                  <h6 className="title">Joining free </h6>
                </div>
              </div>
            </div>
            <div className="why-item wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.4s">
              <div className="why-inner">
                <div className="why-thumb">
                  <img src="assets/img/why2.png" alt="why" />
                </div>
                <div className="why-content">
                  <h6 className="title">Quick Lock</h6>
                </div>
              </div>
            </div>
            <div className="why-item wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
              <div className="why-inner">
                <div className="why-thumb">
                  <img src="assets/img/why3.png" alt="why" />
                </div>
                <div className="why-content">
                  <h6 className="title"> Bonues</h6>
                </div>
              </div>
            </div>
            <div className="why-item wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.6s">
              <div className="why-inner">
                <div className="why-thumb">
                  <img src="assets/img/why4.png" alt="why" />
                </div>
                <div className="why-content">
                  <h6 className="title">Unlimited Transactions</h6>
                </div>
              </div>
            </div>
          </div>
          {/* <a href="https://dashboard.wealth-crest.com/" className="custom-button button-1 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">Open account</a> */}
        </div>
      </div>
      <div className="col-lg-6 d-lg-block d-none">
        <div className="why--thumb wow fadeInRight" data-wow-duration="0.3s" data-wow-delay="0.3s">
          <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1693574523/linctransfer_green.png" alt="why" className="why" />
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Feature2