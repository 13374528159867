import React from 'react'

const Faq = () => {
  return (
    <div>
  <div className="banner faq" id="home" style={{backgroundImage: 'url("https://res.cloudinary.com/dzxyvdq14/image/upload/v1657784520/aboutcran.jpg")'}}>
    {/* hero-area Start */}
    <div className="hero-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-8">
            <div className="banner-content">
              <h2 className="title wow fadeInUp" data-wow-duration="0.3s">FAQ</h2>
              <ul className="breadcrumb wow fadeInUp" data-wow-duration="0.3s">
                <li>
                  <a href="/">Home</a> <i className="flaticon-airplane49" />
                </li>
                <li>
                </li><li>
                  FAQ
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Faq Start */}
  <div className="faq">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="upper-content wow fadeInUp" data-wow-duration="0.3s">
            <h4 className="lasthead">You Have Questions</h4>
            <h2 className="title">We Have Answers.</h2>
            <p className="text">
              Do not hesitate to send us an email if you can't find what you're looking for.
            </p>
          </div>
        </div>
        <div className="col-12">
          <ul className="nav nav-pills wow fadeInUp" data-wow-duration="0.3s" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link button-1 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">BASIC</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link button-1" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">FINANCIAL</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link button-1" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">AFFILIATE</button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      What is the minimum percentage that an investor can earn on Arbitrage Global Limited?
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our percentage ROIs are very dynamic and are dependent on the generated trading earning, but we promise a fixed divided to be paid depending on the plan your decide to invest
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      Can i invest using cryptocurrency?
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Yes! we only accept cryptocurrency payments for now due to the irregularities of the fiat currency we accept and prefer cryptocurrency payments
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      What are the minimum and maximum deposit amounts?
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      The minimum and maximum deposits are depended on the investment plan you choose. Kindly visit our pricing section to select any plan that matches your choice
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      How long will the money arrive in my account after the withdrawal process?
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We process all transactions as swift as possible but the maximum duration is 24 hours
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      What payment system can i use to withdraw?
                    </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      You can withdraw your earnings only through your provided cryptocurrency wallet
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      What is the minimum percentage that an investor can earn on Arbitrage Global Limited?
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our percentage ROIs are very dynamic and are dependent on the generated trading earning, but we promise a fixed divided to be paid depending on the plan your decide to invest
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      Can i invest using cryptocurrency?
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Yes! we only accept cryptocurrency payments for now due to the irregularities of the fiat currency we accept and prefer cryptocurrency payments
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      What are the minimum and maximum deposit amounts?
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      The minimum and maximum deposits are depended on the investment plan you choose. Kindly visit our pricing section to select any plan that matches your choice
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      How long will the money arrive in my account after the withdrawal process?
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We process all transactions as swift as possible but the maximum duration is 24 hours
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      What payment system can i use to withdraw?
                    </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      You can withdraw your earnings only through your provided cryptocurrency wallet
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      What is the minimum percentage that an investor can earn on Arbitrage Global Limited?
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our percentage ROIs are very dynamic and are dependent on the generated trading earning, but we promise a fixed divided to be paid depending on the plan your decide to invest
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      Can i invest using cryptocurrency?
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Yes! we only accept cryptocurrency payments for now due to the irregularities of the fiat currency we accept and prefer cryptocurrency payments
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      What are the minimum and maximum deposit amounts?
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      The minimum and maximum deposits are depended on the investment plan you choose. Kindly visit our pricing section to select any plan that matches your choice
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      How long will the money arrive in my account after the withdrawal process?
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      We process all transactions as swift as possible but the maximum duration is 24 hours
                    </div>
                  </div>
                </div>
                <div className="accordion-item wow fadeInUp" data-wow-duration="0.3s">
                  <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <img src="assets/img/faq-icon.png" alt="none" className="icon" />
                      What payment system can i use to withdraw?
                    </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      You can withdraw your earnings only through your provided cryptocurrency wallet
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Faq